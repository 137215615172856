
    <nav class="navbar navbar-expand-lg  py-2"  (click)="_dashboardService.closePopup()">
      <div class="container">
        <div style="display: grid;">
          <a class="navbar-brand navbar-brand1 pointer-none p-0" href="javascript:void(0)">
            <img [src]="logoUrl" alt="">
          </a>
          <kendo-skeleton *ngIf="isDataLoading" style="width: 100px; height: 50px;"></kendo-skeleton>
          <a class="navbar-brand p-0" href="javascript:void(0)">{{heading}}</a>
        </div>
        <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button> -->

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto align-items-center">
            <li class="nav-item">
              <a class="nav-link cursor-pointer" *ngIf="_token.hasValue && showHomeIcon && !isDataLoading" (click)="gotoHome()">
                <img src="../../../assets/img/home-icon.svg" alt="" class="mb-1 mr-2"> Home </a>
                <button class="btn btn-login" *ngIf="!isLoginPage && !isDataLoading" (click)="login()">Register</button>
            </li>
            <kendo-skeleton *ngIf="isDataLoading" style="width: 100px; height: 50px;"></kendo-skeleton>
            <li class="nav-item" *ngIf="_token.hasValue && !_route.snapshot.queryParams['conference'] && !isDataLoading">
              <a class="nav-link" href="javascript:void(0)" role="button" data-toggle="dropdown" aria-expanded="false" #anchor (click)="onToggle()">
                <img [src]="profileData?.image || '../../../assets/img/user-profile.svg'" alt="" width="36" height="36" style="border-radius: 50%;">
              </a>
              <kendo-popup
              [anchor]="anchor"
              (anchorViewportLeave)="show = false"
              *ngIf="show"
              popupClass="popup-class"
              class="profile-popup"
            >
            <div class="avatar-options">
              <div class="details-container">
                <a class="nav-link" href="javascript:void(0)" role="button" data-toggle="dropdown" aria-expanded="false">
                  <img [src]="profileData?.image || '../../../assets/img/user-profile.svg'" alt="" width="72" height="72" style="border-radius: 50%;">
                </a>
                <div class="name-container">
                  <p class="name">{{ profileData.name }}</p>
                  <p class="email">{{ profileData.email }}</p>
                </div>
              </div>
              
             <div>
              <a class="option logout" (click)="logout()">
                <img src="../../../assets/img/log-out.svg" alt="" width="26" height="26"> Logout             
              </a>
             </div>
            </div>
            </kendo-popup>
            </li>
          </ul>
        </div>
      </div>
    </nav>
 