import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Token } from '@token';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store'
import { HomeState } from './store/home/home.state';
import { HOME } from './constants/route.constants';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  HOME = HOME
  showHeader: boolean = false;
  conference!: any
  isDataLoading: boolean = true;
  
  constructor(private _token: Token, public _router: Router, private _store: Store, private cdRef: ChangeDetectorRef, public _route: ActivatedRoute) {
 
  }

  ngOnInit(): void {
    this._store.select(HomeState.isDataLoading).subscribe(loading => {
      this.isDataLoading = loading
})
   this._route.queryParams.subscribe((params) => {
    this.conference = params['conference']
    console.log(this.conference)
    })
    this._store.select(HomeState.showHeader).subscribe(show => {
      this.showHeader = show
      this.cdRef.detectChanges();
    })
  }

  onScroll(event:any) {
    this._token.triggerToken(event)
  }
}
