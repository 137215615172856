<header *ngIf="showHeader">
  <app-header></app-header>
</header>
<div class="content" (scroll)="onScroll($event)">
  <router-outlet></router-outlet>
</div>
<footer class="footer" [style.position]="!!conference ? 'unset' : 'fixed'" *ngIf="!isDataLoading">
  <app-footer></app-footer>
</footer>
<!-- <div class="loader-overlay" *ngIf="loading">
  <div class="loader-wrapper labels-wrapper">
    <div class="loader-content k-loading-image"></div>
  </div>
</div> -->
